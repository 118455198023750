import BasePage from '@/found/components/table_page';

export default {
  name: 'branch_office_activity',
  extends: BasePage,
  data() {
    return {

    };
  },
  components: {

  },
  async created() {
    await this.getConfigList('branch_office_activity');
  },
  methods: {},
};
